<template>
  <Breadcrumb :home="home" :model="items"/>
  <div class="card p-mt-3">
    <Button>上传报表</Button>
  </div>
</template>

<script>
export default {
  name: "funds",
  data(){
    return{
      home: {icon: 'pi pi-home', to: '/'},
      items: [
        {label: '资金管理'},
      ]
    }
  }
}
</script>

<style scoped>

</style>